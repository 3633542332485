export class User {
  id: number;
  company_id: number;
  email: string;
  first_name: string;
  last_name: string;
  sys_admin: boolean;
  details: string;
  company_name: string;
  account_balance: number;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.company_id = obj.company_id || 0;
    this.email = obj.email || '';
    this.first_name = obj.first_name || '';
    this.last_name = obj.last_name || '';
    this.sys_admin = obj.sys_admin || null;
    this.details = obj.details || null;
    this.company_name = obj.company_name || null;
    this.account_balance = obj.account_balance || 0;
  }
}
