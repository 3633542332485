import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from '../http/http.service';
import { NGXLogger } from 'ngx-logger';
import { BACKEND_ENDPOINTS } from '../../../../environments/backend-endpoints';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { UserCredentials } from '../../models/user-credentials';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import * as crypto from 'crypto-js';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthService implements OnDestroy {
  userSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private logger: NGXLogger,
    private router: Router,
  ) {
  }

  public login(login_details: UserCredentials) {

    return new Promise(resolve => {
      login_details.password = this.encryptPassword(login_details.password);
      this.httpService.post(BACKEND_ENDPOINTS.userLogin, login_details).subscribe(
        result => {
          this.logger.debug(result);
          localStorage.setItem(environment.tokenName, result.token);
          this.userService.getUserObjectFromServer();
          this.userSubscription = this.userService.getUserObservable().subscribe(user => {
            if (user) {
              resolve(true);
              this.router.navigate(['/dashboard/vouchers']);
            }
          });
        }, err => {
          this.logger.error('Invalid login!', err);
          resolve(false);
        });
    });
  }

  public isLoggedIn() {
    const token = this.getToken();
    return !jwtHelper.isTokenExpired(token);
  }

  public getToken() {
    return localStorage.getItem(environment.tokenName);
  }

  public logout() {
    localStorage.clear();
    this.userService.clearUserObservable();
    // window.location.reload();
    this.router.navigate(['/login']);
  }

  public encryptPassword(pwd: string) {
    return crypto.SHA256('acn034986' + pwd + 'acn034986').toString(crypto.enc.Base64);
  }

  public forgotPassword(email) {
    return this.httpService.post(BACKEND_ENDPOINTS.forgotPassword, email);
  }

  public resetForgottenPassword(pwd: string, token: string) {
    return this.httpService.post(BACKEND_ENDPOINTS.resetForgottenPassword, {
      password: this.encryptPassword(pwd),
      token: token
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
