import { Pipe, PipeTransform } from '@angular/core';
import * as Fuse from 'fuse.js';
import * as _ from 'lodash';
import { DisplayData, Row } from './row';

@Pipe({
  name: 'customTableFilter'
})
export class CustomTableFilterPipe implements PipeTransform {
  default = {
    supportHighlight: false,
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    findAllMatches: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    includeScore: false
  };

  transform(
    rows: Row[],
    search: string,
    options: any = {},
    showAll: boolean = false
  ): Object[] {
    if (!rows) {
      return [];
    }

    if (search === '' || !search || rows.length === 0) {
      return rows;
    }

    const fuseOptions = Object.assign({}, this.default, options);

    const firstRow = rows[0];
    const firstRowData = firstRow.displayData;
    const numKeys = firstRowData.length;
    const keys: string[] = [];
    for (let i = 0; i < numKeys; i++) {
      keys.push(`item_${i}`);
    }

    fuseOptions.keys = keys;

    const extracted = rows.map((row) => {
      const displayData = row.displayData;
      const data = displayData.reduce((a: any, b: DisplayData, i: number) => {
        a[`item_${i}`] = b.value;
        return a;
      }, {});
      data['id'] = row.id;
      return data;
    });

    const fuse = new Fuse(extracted, fuseOptions);
    const matches = fuse.search(search);

    const found = rows.filter((row) => {
      const id = row.id;
      return _.find(matches, (m: any) => m.id === id);
    });

    let other = [];

    if (showAll) {
      other = rows.filter((row) => {
        const id = row.id;
        return !_.find(matches, (m: any) => m.id === id);
      });
    }

    return found.concat(other);
  }
}
