import {NGXLogger} from "ngx-logger";

export class CustomTableOrderingService {
  headers: string[];
  orderedBy: { [header: string]: boolean };
  canOrderBy: { [header: string]: boolean };
  orderedByAsc: { [header: string]: boolean };

  private logger: NGXLogger;
  /**
   * Creates an instance of TableHelperService.
   * @param {{ [header: string]: boolean }} headers The headers that the table
   * has as well as whether or not you can order by that header
   * @param {string} [defaultOrder=''] The default header to order by (if
   * applicable)
   * @param {boolean} defaultOrderDirectionAsc
   * @memberof TableHelperService
   */
  constructor(
    headers: { [header: string]: boolean },
    defaultOrder: string = '',
    defaultOrderDirectionAsc: boolean = true
  ) {
    this.canOrderBy = headers;
    this.orderedBy = {};
    this.orderedByAsc = {};

    this.headers = Object.keys(headers).filter((header) =>
      headers.hasOwnProperty(header)
    );

    this.resetOrderedBy();
    if (defaultOrder) {
      this.orderBy(defaultOrder);
      if (!defaultOrderDirectionAsc) {
        // Order again if it's desc
        this.orderBy(defaultOrder);
      }
    }
  }

  isOrder(header: string): boolean {
    if (!this.exists(header)) {
      const orderedBy = this.orderedBy;
      this.logger.error(
        `You're trying to see if we're ordering by ${header} but this header does not exist in the orderedBy object`,
        { orderedBy }
      );
    }

    return this.orderedBy[header];
  }

  orderDirectionAsc(header: string): boolean {
    if (!this.exists(header)) {
      const orderedByAsc = this.orderedByAsc;
      this.logger.error(
        `You're trying to see the order direction of ${header} but this header does not exist in the orderedByAsc object`,
        { orderedByAsc }
      );
    }
    return this.orderedByAsc[header];
  }

  class(header: string): string {
    if (!this.exists(header)) {
      return '';
    }
    if (!this.orderedBy[header]) {
      return '';
    }
    return this.orderedByAsc[header] ? 'sorted ascending' : 'sorted descending';
  }

  isAsc(header: string): boolean {
    if (!this.exists(header)) {
      const orderedByAsc = this.orderedByAsc;
      this.logger.error(
        `You're trying to see if we're ordering by ${header} but this header does not exist in the orderedByAsc object`,
        { orderedByAsc }
      );
    }

    return this.orderedByAsc[header];
  }

  exists(header: string): boolean {
    return this.orderedBy.hasOwnProperty(header);
  }

  orderBy(header: string): void {
    // If it's already selected then just toggle the direction we're ordering
    // by
    if (this.orderedBy[header]) {
      this.orderedByAsc[header] = !this.orderedByAsc[header];
      return;
    }
    this.resetOrderedBy();
    if (this.canOrderBy[header]) {
      this.orderedBy[header] = true;
    }
  }

  resetOrderedBy(): void {
    this.headers.forEach((header) => (this.orderedBy[header] = false));
    this.headers.forEach((header) => (this.orderedByAsc[header] = true));
  }
}
