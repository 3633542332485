import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productFilter'
})
export class ProductFilterPipe implements PipeTransform {

  transform(items: any): any {
    items.map(item => {
      item['overall'] = `${item['value']} ${item['units']}`
    });
    return items;
  }

}

