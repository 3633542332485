import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class FeedbackService {

  constructor(
    private toast: ToastrService
  ) {

  }

  success(msg: string) {
    this.toast.success(msg);
  }

  error(msg: string) {
    this.toast.error(msg);
  }
}