import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { Row } from '../../row';

@Component({
  selector: 'app-custom-table-template-cell',
  templateUrl: './custom-table-template-cell.component.html',
  styleUrls: ['./custom-table-template-cell.component.scss']
})
export class CustomTableTemplateCellComponent implements OnInit, AfterViewInit {
  @Input() template: TemplateRef<any>;
  @Input() row: Row;
  @Input() index: number;
  constructor(public elementRef: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.row) {
      return;
    }
    this.row.displayData[this.index].value =
      this.elementRef.nativeElement.innerText ||
      (this.elementRef.nativeElement.innerHtml || '').toString();
  }
}
