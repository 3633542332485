import { TemplateRef } from '@angular/core';
import { DataType } from './data-type.enum';

export class Column {
  key: string;
  display: string;
  show = true;
  searchable = true;
  sortable = true;
  defaultSortDirection = 'asc';
  template: TemplateRef<any> = null;
  type: DataType;

  constructor(obj: any = {}) {
    this.key = obj.key || '';
    if (obj.display) {
      this.display = obj.display;
    } else {
      this.display = this.key.replace(/[A-Z]/g, ' $1');
      this.display =
        this.display.charAt(0).toUpperCase() + this.display.slice(1);
    }

    this.show = typeof obj.show === 'boolean' ? obj.show : true;
    this.searchable =
      typeof obj.searchable === 'boolean' ? obj.searchable : true;
    this.sortable = typeof obj.sortable === 'boolean' ? obj.sortable : true;
    this.defaultSortDirection = obj.defaultSortDirection || 'asc';
    this.template = obj.template || null;
    this.type = obj.type || DataType.STRING;
  }
}
