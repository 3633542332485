import { Row } from './row';

export class SelectedObjects {
  [id: number]: boolean;
}

export class CustomTableSelectionService {
  private previousId = -1;

  constructor() {}

  /**
   * Call this function to select a new object and figure out if other
   * selections should change.
   * @param id The id of the object that has just been selected
   * @param select Whether it's changing to true or false in the selection
   * @param shiftKey Whether the shift key was pressed
   * @param selectedObjects All of the selected objects
   * @param allObjects All of the objects
   */
  select(
    id: number,
    select: boolean,
    shiftKey: boolean,
    selectedObjects: SelectedObjects,
    allObjects: Row[]
  ): SelectedObjects {
    // If the user is not holding down shift then we just toggle the selection
    // and send it back.
    if (!shiftKey || this.previousId === -1) {
      selectedObjects[id] = select;
      this.previousId = id;
      return selectedObjects;
    }

    // At this point we know that the shift key is not being pressed and we have
    // an id of the previous selection.
    const prevSelected = selectedObjects[this.previousId];
    if (!select) {
      const selected = this.deselectAllBetween(
        id,
        this.previousId,
        selectedObjects,
        allObjects
      );
      this.previousId = id;
      return selected;
    }

    if (select && prevSelected) {
      const selected = this.selectAllBetween(
        id,
        this.previousId,
        selectedObjects,
        allObjects
      );
      this.previousId = id;
      return selected;
    }

    selectedObjects[id] = select;
    this.previousId = id;
    return selectedObjects;
  }

  /**
   * Sets all of the selections to true between two objects.
   * @param id1 The id of the first object
   * @param id2 The id of the second object
   * @param selectedObjects All of the selected objects
   * @param allObjects All of the objects
   */
  private selectAllBetween(
    id1: number,
    id2: number,
    selectedObjects: SelectedObjects,
    allObjects: Row[]
  ): SelectedObjects {
    const indices = this.getIndexes(id1, id2, allObjects);
    if (!this.bothIdsExist(indices)) {
      return selectedObjects;
    }
    return this.setSelect(
      indices.index1,
      indices.index2,
      selectedObjects,
      allObjects,
      true
    );
  }

  /**
   * Sets all of the selections to false between two objects.
   * @param id1 The id of the first object
   * @param id2 The id of the second object
   * @param selectedObjects All of the selected objects
   * @param allObjects All of the objects
   */
  private deselectAllBetween(
    id1: number,
    id2: number,
    selectedObjects: SelectedObjects,
    allObjects: Row[]
  ): SelectedObjects {
    const indices = this.getIndexes(id1, id2, allObjects);
    if (!this.bothIdsExist(indices)) {
      return selectedObjects;
    }
    return this.setSelect(
      indices.index1,
      indices.index2,
      selectedObjects,
      allObjects,
      false
    );
  }

  /**
   * Sets all of the selections to true/false (depending on what you specify)
   * between two objects.
   * @param id1 The id of the first object
   * @param id2 The id of the second object
   * @param selectedObjects All of the selected objects
   * @param allObjects All of the objects
   */
  private setSelect(
    index1: number,
    index2: number,
    selectedObjects: SelectedObjects,
    allObjects: Row[],
    select: boolean
  ): SelectedObjects {
    const start = index1 > index2 ? index2 : index1;
    const end = start === index1 ? index2 : index1;

    for (let i = start; i <= end; i++) {
      const o = allObjects[i];
      selectedObjects[o.id] = select;
    }

    return selectedObjects;
  }

  /**
   * Ensures that both indices are 0 or larger, meaning the object exists in
   * the array.
   * @param indices The indices that we're checking
   */
  private bothIdsExist(indices: { index1: number; index2: number }) {
    return indices.index1 !== -1 && indices.index2 !== -1;
  }

  /**
   * Checks the indices of objects that have the ids specified in here.
   * @param id1 The id of the first object
   * @param id2 The id of the second object
   * @param allObjects All of the objects that we're searching through
   */
  private getIndexes(id1: number, id2: number, allObjects: Row[]) {
    const index1 = allObjects.findIndex((o) => o.id === id1);
    const index2 = allObjects.findIndex((o) => o.id === id2);
    return {
      index1,
      index2
    };
  }
}
