import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/user';
import { HttpService } from '../http/http.service';
import { NGXLogger } from 'ngx-logger';
import { BACKEND_ENDPOINTS } from '../../../../environments/backend-endpoints';

@Injectable()
export class UserService {

  private currentUser: User;
  public userSubject: BehaviorSubject<User>;

  constructor(
    private httpService: HttpService,
    private logger: NGXLogger
  ) {
    this.userSubject = new BehaviorSubject<User>(this.currentUser);
  }

  getUserObjectFromServer() {
    this.httpService.get(BACKEND_ENDPOINTS.getCurrentUserDetails).subscribe(
      result => {
        this.logger.info(`Retrieved user ${result.first_name} ${result.last_name}`);
        this.currentUser = new User(result);
        this.userSubject.next(this.currentUser);
        localStorage.setItem('user_details', JSON.stringify(this.currentUser));
      },
      err => {
        this.logger.error('Failed to get user object');
      }
    );
  }

  getUserObservable(): Observable<User> {
    if (!this.currentUser) {
      this.currentUser = JSON.parse(localStorage.getItem('user_details'));
      this.userSubject.next(this.currentUser);
    }
    return this.userSubject.asObservable();
  }

  checkUserAdminStatus() {
    if (this.currentUser.sys_admin) {
      return true;
    } else {
      return false;
    }
  }

  clearUserObservable() {
    this.currentUser = undefined;
    this.userSubject.next(null);
  }
}
