import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { SuiModule } from 'ng2-semantic-ui';

import { HttpService } from './services/http/http.service';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { ProductFilterPipe } from './pipes/product-filter.pipe';
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './services/auth/auth.guard';
import { UserService } from './services/user/user.service';
import { LoginGuard } from './services/auth/login.guard';
import { JwtInjectorService } from './services/http-interceptor/jwt-injector.service';
import { FeedbackService } from './services/feedback/feedback.service';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    SuiModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    ToastrModule.forRoot(),
  ],
  exports: [
    CommonModule,
    SuiModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    FormErrorsComponent,
    ProductFilterPipe
  ],
  declarations: [FormErrorsComponent, ProductFilterPipe],
  providers: [
    HttpService,
    AuthService,
    UserService,
    AuthGuard,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInjectorService,
      multi: true,
    },
    FeedbackService
  ]
})
export class SharedModule {
}
