import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTableFilterPipe } from './custom-table-filter.pipe';
import { CustomTableOrderingService } from './custom-table-ordering.service';
import { CustomTableSelectionService } from './custom-table-selection.service';
import { CustomTableTemplateCellComponent } from './custom-table/custom-table-template-cell/custom-table-template-cell.component';
import { CustomTableComponent } from './custom-table/custom-table.component';

import { SuiModule } from 'ng2-semantic-ui';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    SuiModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  declarations: [
    CustomTableComponent,
    CustomTableTemplateCellComponent,
    CustomTableFilterPipe
  ],
  providers: [],
  exports: [
    CustomTableComponent
  ]
})
export class CustomTableModule { }
