export const BACKEND_ENDPOINTS = {
  getProducts: 'get-products',
  createOrder: 'create-order',
  customAirtimeRecharge: 'custom-airtime-recharge',
  userLogin: 'user-login',
  getCurrentUserDetails: 'get-current-user-details',
  createCompany: 'create-company-account',
  createUser: 'create-user-account',
  getVouchers: 'vouchers',
  getRedeemedVouchers: 'vouchers/redeemed',
  getAvailableVouchers: 'vouchers/not-redeemed',

  // ****** Company controller
  getAllCompanies: 'get-all-companies',
  getCompany: 'get-company-info',
  topUpCompanyAccount: 'top-up-account',

  forgotPassword: 'forgot-password',
  resetForgottenPassword: 'reset-forgotten-password'
};

