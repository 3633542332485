import { HttpType } from './http.enum';
import { Observable  } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RequestOptions, Http, Response, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { NGXLogger } from 'ngx-logger';

/**
 * Since we're versioning our API, I'd like the change in the version to
 * automatically change the API endpoints. In this service, I automatically
 * change the endpoint based on the version.
 *
 * @export
 * @class HttpService
 */
@Injectable()
export class HttpService {
  /**
   * The URL where requests should go. This includes the API version, changes
   * to the version in environment.ts will reflect here automatically.
   *
   * @type {string}
   * @memberOf HttpService
   */
  url: string;
  /**
   * At the moment we just need to ensure that the headers contain the type
   * of information being JSON, but this may change in future.
   *
   * @type {RequestOptions}
   * @memberOf HttpService
   */
  requestOptions: RequestOptions;

  public fullUrl(endpoint: string): string {
    return `${this.url}/${endpoint}`;
  }

  /**
   * Creates an instance of HttpService.
   *
   * @param {Http} http The normal HTTP service that comes with Angular 2.
   * @param {AuthHttp} authHttp The service used to ensure that the JWT is and
   * authenticates the user correctly.
   *
   * @memberOf HttpService
   */
  constructor(
    private http: HttpClient,
    // private authHttp: AuthHttp,
    private logger: NGXLogger
  ) {
    // Create the headers that are used in the requests
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    this.requestOptions = new RequestOptions({
      headers: headers
    });

    // Get the backend URL
    this.url = `${environment.backendUrl}`;
  }

  /**
   * Unauthenticated GET request.
   *
   * @param {string} endpoint The endpoint on the backend that we need to
   * request from.
   * @param {HttpType} type The type of data that the response must be mapped
   * too.
   * @returns {Observable< any >}
   *
   * @memberOf HttpService
   */
  get(endpoint: string, type: string = HttpType.JSON): Observable<any> {
    endpoint = this.fullUrl(endpoint);
    this.logger.debug(`Making a GET request to ${endpoint}`, {
      endpoint,
      type
    });
    switch (type) {
      case HttpType.JSON:
        return this.http.get(endpoint, { responseType: 'json' });
      case HttpType.ARRAY_BUFFER:
        return this.http.get(endpoint, {responseType: 'arraybuffer' });
      case HttpType.BLOB:
        return this.http.get(endpoint, { responseType: 'blob' });
      case HttpType.TEXT:
        return this.http.get(endpoint, { responseType: 'text' });
      default:
        return this.http.get(endpoint, { responseType: 'json' });
    }
  }

  /**
   * Unauthenticated POST request.
   *
   * @param {string} endpoint The endpoint on the backend that we need to
   * post too.
   * @param {*} data The data that should be posted to the endpoint
   * @param {HttpType} type The type of data that the response must be mapped
   * too.
   * @returns {Observable< any >}
   *
   * @memberOf HttpService
   */
  post(
    endpoint: string,
    data: any,
    type: HttpType = HttpType.JSON
  ): Observable<any> {
    endpoint = this.fullUrl(endpoint);
    this.logger.debug(`Making a POST request to ${endpoint}`, {
      endpoint,
      data,
      type
    });
    const body = data;
    switch (type) {
      case HttpType.JSON:
        return this.http.post(endpoint, body, { responseType: 'json' });
      case HttpType.ARRAY_BUFFER:
        return this.http.post(endpoint, body, { responseType: 'arraybuffer' });
      case HttpType.BLOB:
        return this.http.post(endpoint, body, { responseType: 'blob' });
      case HttpType.TEXT:
        return this.http.post(endpoint, body, { responseType: 'text' });
      default:
        return this.http.post(endpoint, body, { responseType: 'json' });
    }
  }

    /**
   * Unauthenticated PUT request.
   *
   * @param {string} endpoint The endpoint on the backend that we need to
   * post too.
   * @param {*} data The data that should be posted to the endpoint
   * @param {HttpType} type The type of data that the response must be mapped
   * too.
   * @returns {Observable< any >}
   *
   * @memberOf HttpService
   */
  put(
    endpoint: string,
    data: any,
    type: HttpType = HttpType.JSON
  ): Observable<any> {
    endpoint = this.fullUrl(endpoint);
    this.logger.debug(`Making a PUT request to ${endpoint}`, {
      endpoint,
      data,
      type
    });
    const body = data;
    switch (type) {
      case HttpType.JSON:
        return this.http.put(endpoint, body, { responseType: 'json' });
      case HttpType.ARRAY_BUFFER:
        return this.http.put(endpoint, body, { responseType: 'arraybuffer' });
      case HttpType.BLOB:
        return this.http.put(endpoint, body, { responseType: 'blob' });
      case HttpType.TEXT:
        return this.http.put(endpoint, body, { responseType: 'text' });
      default:
        return this.http.put(endpoint, body, { responseType: 'json' });
    }
  }

  /**
   * Authenticated GET request.
   *
   * @param {string} endpoint The endpoint where we're getting the data from.
   * @param {HttpType} type The type of data that the response must be mapped
   * too.
   * @returns {Observable< any >}
   *
   * @memberOf HttpService
   */
  // getSecure(endpoint: string, type: HttpType = HttpType.JSON): Observable<any> {
  //   this.logger.debug(`Making a secure GET request to ${endpoint}`, {
  //     endpoint,
  //     type
  //   });
  //   return this.authHttp.get(this.fullUrl(endpoint)).map((res: Response) => {
  //     switch (type) {
  //       case HttpType.JSON:
  //         return res.json();
  //       case HttpType.TEXT:
  //         return res.text();
  //       case HttpType.ARRAY_BUFFER:
  //         return res.arrayBuffer();
  //       case HttpType.BLOB:
  //         return res.blob();
  //       default:
  //         return res.json();
  //     }
  //   });
  // }

  // /**
  //  * Authenticated POST request.
  //  *
  //  * @param {string} endpoint The endpoint that we're posting too.
  //  * @param {*} data The data that we want to post.
  //  * @param {HttpType} type The type of data that the response must be mapped
  //  * too.
  //  * @returns {Observable<any>}
  //  *
  //  * @memberOf HttpService
  //  */
  // postSecure(
  //   endpoint: string,
  //   data: any,
  //   type: HttpType = HttpType.JSON
  // ): Observable<any> {
  //   this.logger.debug(`Making a secure POST request to ${endpoint}`, {
  //     endpoint,
  //     data,
  //     type
  //   });
  //   const body = JSON.stringify(data);
  //   return this.authHttp
  //     .post(this.fullUrl(endpoint), body, this.requestOptions)
  //     .map((res: Response) => {
  //       switch (type) {
  //         case HttpType.JSON:
  //           return res.json();
  //         case HttpType.TEXT:
  //           return res.text();
  //         case HttpType.ARRAY_BUFFER:
  //           return res.arrayBuffer();
  //         case HttpType.BLOB:
  //           return res.blob();
  //         default:
  //           return res.json();
  //       }
  //     });
  // }
  // /**
  //  * Authenticated PUT request.
  //  *
  //  * @param {string} endpoint The endpoint where we'd like to put data.
  //  * @param {*} data The data that we'd like to put.
  //  * @param {HttpType} type The type of data that the response must be mapped
  //  * too.
  //  * @returns {Observable< any >}
  //  *
  //  * @memberOf HttpService
  //  */
  // putSecure(
  //   endpoint: string,
  //   data: any,
  //   type: HttpType = HttpType.JSON
  // ): Observable<any> {
  //   this.logger.debug(`Making a secure PUT request to ${endpoint}`, {
  //     endpoint,
  //     data,
  //     type
  //   });
  //   const body = JSON.stringify(data);
  //   return this.authHttp
  //     .put(this.fullUrl(endpoint), body, this.requestOptions)
  //     .map((res: Response) => {
  //       switch (type) {
  //         case HttpType.JSON:
  //           return res.json();
  //         case HttpType.TEXT:
  //           return res.text();
  //         case HttpType.ARRAY_BUFFER:
  //           return res.arrayBuffer();
  //         case HttpType.BLOB:
  //           return res.blob();
  //         default:
  //           return res.json();
  //       }
  //     });
  // }

  // /**
  //  * Authenticated DELETE request.
  //  *
  //  * @param {string} endpoint The endpoint where we'd like to delete data from.
  //  * @param {HttpType} type The type of data that the response must be mapped
  //  * too.
  //  * @returns {Observable< any >}
  //  *
  //  * @memberOf HttpService
  //  */
  // deleteSecure(
  //   endpoint: string,
  //   type: HttpType = HttpType.JSON
  // ): Observable<any> {
  //   this.logger.debug(`Making a secure DELETE request to ${endpoint}`, {
  //     endpoint,
  //     type
  //   });
  //   return this.authHttp.delete(this.fullUrl(endpoint)).map((res: Response) => {
  //     switch (type) {
  //       case HttpType.JSON:
  //         return res.json();
  //       case HttpType.TEXT:
  //         return res.text();
  //       case HttpType.ARRAY_BUFFER:
  //         return res.arrayBuffer();
  //       case HttpType.BLOB:
  //         return res.blob();
  //       default:
  //         return res.json();
  //     }
  //   });
  // }
}
