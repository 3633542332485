import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Column } from './column';
import { CustomTableTemplateCellComponent } from './custom-table/custom-table-template-cell/custom-table-template-cell.component';

import * as _ from 'lodash';

export class DisplayData {
  value: any;
  template = null;

  constructor(value: any, template = null) {
    this.value = value;
    this.template = template;
  }
}

export class Row {
  static rowNumber = 0;

  data: any;
  displayData: DisplayData[] = [];
  id: number;

  constructor(row: any, columnInformation: Column[]) {
    this.data = row;
    this.displayData = this.extractDisplayData(columnInformation);
    this.id = ++Row.rowNumber;
  }

  private extractDisplayData(columnInformation: Column[]): DisplayData[] {
    const result = [];
    columnInformation.filter((column) => column.show).forEach((column) => {
      if (column.template !== null) {
        const context = {
          row: this.data,
          $implicit: this.data
        };
        const rendered = column.template.createEmbeddedView(context);
        // Render the context
        rendered.detectChanges();

        // Extract the elements
        const rootNodes = rendered.rootNodes;

        let text = '';
        rootNodes.forEach(rootNode => {
          text += (rootNode.innerText || rootNode.innerHTML) + ' ';
        });
        result.push(new DisplayData(text, column.template));
      } else {

        const value = _.get(this.data, column.key) || '';
        result.push(new DisplayData(value));
      }
    });
    return result;
  }
}
