import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { Column } from '../column';
import { CustomTableOptions } from '../custom-table-options';
import { CustomTableOrderingService } from '../custom-table-ordering.service';
import { CustomTableSelectionService } from '../custom-table-selection.service';
import { CustomTableTemplateCellComponent } from '../custom-table/custom-table-template-cell/custom-table-template-cell.component';
import { Row } from '../row';

import * as _ from 'lodash';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {
  /**
   * The rows set by the user, this is then modified to fit the structure
   * required for the table.
   *
   * @private
   * @type {any[]}
   * @memberof CustomTableComponent
   */
  private _rows: any[];
  @Input()
  get rows() {
    return this._rows;
  }
  set rows(rows) {
    const same = _.isEqual(this._rows, rows);
    this._rows = rows || [];

    // If the rows have changed then we should regenerate the table data
    if (!same) {
      this.columns = this.columns;
    }
  }

  /**
   * The columns set by the user, we then modify this to fit our structure.
   *
   * @private
   * @type {any[]}
   * @memberof CustomTableComponent
   */
  private _columns: any[];
  @Input()
  get columns() {
    return this._columns;
  }
  set columns(columns) {
    this._columns = columns || [];
    this._tableColumns = this.columns.map((column) => new Column(column));

    const headers = this.tableColumns.reduce((a: any, b: any) => {
      a[b.display] = b.sortable;
      return a;
    }, {});

    this.customTableOrderingService = new CustomTableOrderingService(
      headers,
      this.tableOptions.defaultSortHeader,
      this.tableOptions.defaultSortDirectionAsc
    );
    this.rows = this.rows || [];
    this._tableRows = this.rows.map((row) => new Row(row, this._tableColumns));
  }

  /**
   * The table rows used to display the actual data
   *
   * @private
   * @type {Row[]}
   * @memberof CustomTableComponent
   */
  private _tableRows: Row[] = [];
  get tableRows() {
    return this._tableRows;
  }

  set tableRows(rows) {
    this._tableRows = rows;
  }

  /**
   * The table columns used to display the actual data
   *
   * @private
   * @type {Column[]}
   * @memberof CustomTableComponent
   */
  private _tableColumns: Column[] = [];
  get tableColumns() {
    return this._tableColumns;
  }

  private _tableOptions: CustomTableOptions = new CustomTableOptions();

  @Input()
  get tableOptions() {
    return this._tableOptions;
  }
  set tableOptions(options) {
    this._tableOptions = new CustomTableOptions(options);
  }

  private _selectedRowsObject: { [id: number]: boolean } = {};

  get selectedRowsObject() {
    return this._selectedRowsObject;
  }
  set selectedRowsObject(selectedRowsObject) {
    this._selectedRowsObject = selectedRowsObject;
    this._selectedRows = this.tableRows
      .filter((row) => this._selectedRowsObject[row.id] === true)
      .map((row) => row.data);
  }

  private _selectedRows: any[] = [];
  @Input()
  get selected() {
    return this._selectedRows;
  }

  selectAll = false;

  customTableOrderingService = new CustomTableOrderingService({}, '', true);
  customTableSelectionService = new CustomTableSelectionService();

  orderedHeader = '';
  orderedHeaderDirectionAsc = true;

  searchTerm = '';

  itemsPerPageOptions = [10, 20, 50, 100, 200, 500];

  constructor() {}

  ngOnInit() {
    // Make sure that we create the row data correctly once we have the column
    // information.
    this.columns = this._columns;
    this.orderedHeader = this.tableOptions.defaultSortHeader;
    this.orderedHeaderDirectionAsc = this.tableOptions.defaultSortDirectionAsc;
    if (this.orderedHeader) {
      this.orderBy(this.orderedHeader);
    }
  }

  onSelectRow(row: Row, event): void {
    this.selectedRowsObject = this.customTableSelectionService.select(
      row.id,
      !(this.selectedRowsObject[row.id] === true),
      event.shiftKey,
      this.selectedRowsObject,
      this.tableRows
    );
  }

  onSelectAll() {
    this.selectAll = !this.selectAll;
    this.tableRows.forEach((row) => {
      this.selectedRowsObject = this.customTableSelectionService.select(
        row.id,
        this.selectAll,
        false,
        this.selectedRowsObject,
        this.tableRows
      );
    });
  }

  orderBy(header: string): void {
    this.customTableOrderingService.orderBy(header);
    if (!this.customTableOrderingService.isOrder(header)) {
      return;
    }
    this.orderedHeader = header;
    this.orderedHeaderDirectionAsc = this.customTableOrderingService.orderDirectionAsc(
      header
    );
    this.orderRows();
  }

  headerClass(header: string): string {
    return this.customTableOrderingService.class(header);
  }

  private orderRows(): void {
    const index = this.tableColumns
      .filter((column) => column.show)
      .findIndex((column) => column.display === this.orderedHeader);

    if (index < 0) {
      return;
    }

    const asc = this.orderedHeaderDirectionAsc;

    this.tableRows = this.tableRows.sort((a, b) => {
      if (_.isEqual(a.displayData[index].value, b.displayData[index].value)) {
        return 0;
      }

      const greater = _.gt(
        a.displayData[index].value,
        b.displayData[index].value
      )
        ? 1
        : -1;

      return asc ? -1 * greater : greater;
    });
  }
}
