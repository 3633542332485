export class CustomTableOptions {
  tableClass: string;
  searchKeys: string[] = [];
  itemsPerPage: number;
  page: number;
  defaultSortHeader: string;
  defaultSortDirectionAsc: boolean;
  selectable: boolean;

  constructor(options: any = {}) {
    this.tableClass =
      options.tableClass ||
      'ui sortable single line selectable table';
    this.searchKeys = options.searchKeys || [];

    // In my row items I embed the original data in a "data" field, so I need
    // to add that to the search keys if it isn't there already. I also need to
    // be careful that someone might have a "data" object in their object so
    // I'm ensuring that ALL of them have the .data prefix, otherwise I add the
    // prefix to all of them
    let dataPrefix = true;
    this.searchKeys.forEach(
      (key) => (dataPrefix = /^data\./g.test(key) && dataPrefix)
    );
    if (!dataPrefix) {
      this.searchKeys = this.searchKeys.map((key) => 'data.' + key);
    }
    this.itemsPerPage = options.itemsPerPage || 10;
    this.page = options.page || 0;
    this.defaultSortHeader = options.defaultSortHeader || '';
    this.defaultSortDirectionAsc =
      typeof options.defaultSortDirectionAsc === 'boolean'
        ? options.defaultSortDirectionAsc
        : true;
    this.selectable = options.selectable === true;
  }
}
